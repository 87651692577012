import React from 'react';

const Logo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="300"
    height="31"
    viewBox="0 0 300 31"
  >
    <g>
      <path
        d="M0,9.6H5.1v2.9h.1a7.3,7.3,0,0,1,6.2-3.4c2.9,0,5,1.1,6.2,3.7a7.1,7.1,0,0,1,6.5-3.7c5.2,0,6.7,3.5,6.7,8.2V30.5H25.3V18c0-1.9,0-4.7-3.1-4.7s-4.1,4.3-4.1,7.1V30.5H12.7V18c0-1.9,0-4.7-3.2-4.7s-4,4.3-4,7.1V30.5H0Z"
        fill="#ff0082"
      />
      <path d="M36.4.5h5.5V5.7H36.4Zm0,9.1h5.5V30.5H36.4Z" fill="#ff0082" />
      <path
        d="M61.2,14.1a7.5,7.5,0,0,0-3.7-.8c-3.4,0-5.5,2.7-5.5,6.6s1.8,6.9,5.6,6.9a11.3,11.3,0,0,0,4.2-1l.3,4.5a17.9,17.9,0,0,1-5.5.7c-6.9,0-10.3-4.7-10.3-11.1S49.9,9.1,56.4,9.1a16.2,16.2,0,0,1,5.3.7Z"
        fill="#ff0082"
      />
      <path
        d="M65.7,0h5.4V12.4h.1a8,8,0,0,1,6.6-3.3c5,0,7.1,3.5,7.1,8.2V30.5H79.5V19.3c0-2.5,0-6-3.5-6s-4.9,4.3-4.9,7V30.5H65.7Z"
        fill="#ff0082"
      />
      <path
        d="M91.5,10.7a18.4,18.4,0,0,1,7.3-1.6c6.4,0,9,2.6,9,8.8v2.7c0,2.1.1,3.7.1,5.2s.1,3.1.3,4.7h-4.8c-.2-1.1-.2-2.5-.3-3.1H103A7.4,7.4,0,0,1,96.6,31c-3.7,0-7.4-2.2-7.4-6.2a6.2,6.2,0,0,1,3.6-6,17.1,17.1,0,0,1,7.1-1.2h3c0-3.4-1.5-4.5-4.8-4.5a11.2,11.2,0,0,0-6.4,2.2ZM97.9,27a4.5,4.5,0,0,0,3.8-1.8,7.1,7.1,0,0,0,1.2-4.4h-2.4c-2.4,0-6.1.4-6.1,3.7S96,27,97.9,27Z"
        fill="#ff0082"
      />
      <path
        d="M130,29.4a15.3,15.3,0,0,1-7.2,1.6c-6.9,0-10.9-4-10.9-10.8s3.2-11.1,9.7-11.1,10,5.3,10,12.6H117.1c.2,3.4,2.6,5.3,6,5.3a13.4,13.4,0,0,0,6.9-2.1ZM126.4,18c-.2-2.7-1.4-4.9-4.5-4.9s-4.6,2.1-4.8,4.9Z"
        fill="#ff0082"
      />
      <path d="M136,0h5.4V30.5H136Z" fill="#ff0082" />
      <path
        d="M158.4,9.6h5.1v2.9h.1a7.3,7.3,0,0,1,6.2-3.4c3,0,5,1.1,6.2,3.7a7.3,7.3,0,0,1,6.6-3.7c5.1,0,6.6,3.5,6.6,8.2V30.5h-5.4V18c0-1.9,0-4.7-3.2-4.7s-4.1,4.3-4.1,7.1V30.5h-5.4V18c0-1.9,0-4.7-3.1-4.7s-4.1,4.3-4.1,7.1V30.5h-5.5Z"
        fill="#ff0082"
      />
      <path
        d="M214,30.5h-5.2V27.7h-.1a8.7,8.7,0,0,1-6.9,3.3c-4.9,0-7.1-3.5-7.1-8.2V9.6h5.5V20.8c0,2.5,0,6,3.5,6s4.8-4.3,4.8-7V9.6H214Z"
        fill="#ff0082"
      />
      <path
        d="M236.4,29.4a15.6,15.6,0,0,1-7.2,1.6c-6.9,0-10.9-4-10.9-10.8S221.5,9.1,228,9.1s10,5.3,10,12.6H223.5c.2,3.4,2.6,5.3,6,5.3a13.4,13.4,0,0,0,6.9-2.1ZM232.8,18c-.2-2.7-1.4-4.9-4.5-4.9s-4.6,2.1-4.8,4.9Z"
        fill="#ff0082"
      />
      <path d="M242.4,0h5.4V30.5h-5.4Z" fill="#ff0082" />
      <path d="M253.7,0h5.5V30.5h-5.5Z" fill="#ff0082" />
      <path
        d="M281.7,29.4a15.6,15.6,0,0,1-7.2,1.6c-6.9,0-10.9-4-10.9-10.8s3.2-11.1,9.7-11.1,10,5.3,10,12.6H268.8c.2,3.4,2.6,5.3,5.9,5.3a13.4,13.4,0,0,0,7-2.1ZM278,18c-.1-2.7-1.3-4.9-4.4-4.9s-4.6,2.1-4.8,4.9Z"
        fill="#ff0082"
      />
      <path
        d="M287.7,9.6h4.8v4.7h.1c.2-1.9,2.5-5.2,5.7-5.2H300v5.5a7.5,7.5,0,0,0-2.4-.4c-4.5,0-4.5,5.5-4.5,8.5v7.7h-5.4Z"
        fill="#ff0082"
      />
    </g>
  </svg>
);

export default Logo;
